import styled from 'styled-components';

const MenuWrapper = styled.div`
  padding-top: 140px;
  width: 60%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background: ${({ theme }) => theme.color.background.secondary};
`;

const MenuClose = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export { MenuWrapper, MenuClose };

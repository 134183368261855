import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import * as S from './styled';
import Input from '../../components/UI/Input';
import { api } from '../../services';
import AlertMessage from '../../components/UI/AlertMessage';
import { useNavigate } from 'react-router-dom';
import { ALERT_TYPES } from '../../enums';

const VerificationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [text, setText] = useState('');
  const [alertStatus, setAlertStatus] = useState(ALERT_TYPES.ERROR);

  const validationSchema = Yup.object({
    code: Yup.string().required(t('auth.email_required')).default('')
  });

  const submitForm = async ({ code }) => {
    await api.authenticate
      .verifyAccount(code)
      .then(() => {
        setIsShowAlert(true);
        setAlertStatus(ALERT_TYPES.SUCCESS);
        setText('Account is success verified!');
        setTimeout(() => {
          navigate('/');
        }, 1500);
      })
      .catch(() => {
        setIsShowAlert(true);
        setAlertStatus(ALERT_TYPES.ERROR);
        setText('Invalid verification code');
      });
  };

  const handlerCloseAlert = () => {
    setIsShowAlert(false);
  };

  const formik = useFormik({
    initialValues: validationSchema.cast(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  return (
    <S.Wrapper>
      <AlertMessage
        open={isShowAlert}
        onClose={handlerCloseAlert}
        errorText={text}
        severity={alertStatus}
      />
      <S.HeaderBlock>
        <S.HeaderText>{t('auth.dont_have_account')}</S.HeaderText>

        <S.HeaderTextBold to={'/sign-in'}>&nbsp;{t('auth.get_started')}</S.HeaderTextBold>
      </S.HeaderBlock>

      <S.FormBlock>
        <S.FormTitle>{t('auth.verify_title')}</S.FormTitle>
        <S.FormDescription>{t('auth.verify_description')}</S.FormDescription>
        <Input
          label={t('auth.verify_code')}
          id="code"
          name="code"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.email}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />

        <S.ActionButton text={t('auth.confirm_code')} onClick={formik.handleSubmit} />

        <S.ActionButton resend="true" text={t('auth.resend_code')} />

        <S.NotAccountBlock>
          <S.LinkForPage to={'/login'}>{t('auth.back_to_login')}</S.LinkForPage>
        </S.NotAccountBlock>
      </S.FormBlock>
    </S.Wrapper>
  );
};

export default VerificationPage;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { textMedium, textSmall, titleText } from '../../styles/texts';
import CommonButton from '../../components/UI/button';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    min-height: auto;
  }
`;

const HeaderBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 40px 40px 0 0;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: none;
  }
`;

const HeaderText = styled.p`
  ${textSmall};
  line-height: 22px;
  font-weight: 500;
`;

const HeaderTextBold = styled(Link)`
  ${textSmall};
  line-height: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.background.black};
  text-decoration: underline;
`;

const FormBlock = styled.div`
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    max-width: 344px;
  }
`;

const FormTitle = styled.h1`
  margin-bottom: 8px;
  ${titleText};
  line-height: 36px;
`;

const FormDescription = styled.p`
  margin-bottom: 32px;
  ${textMedium};
  line-height: 24px;
  color: ${({ theme }) => theme.color.background.darkGray};
`;

const ActionButton = styled(CommonButton)`
  width: 100%;
  padding: 11px 0;
  display: block;
  font-weight: 700;
  line-height: 26px;

  ${({ resend, theme }) =>
    resend &&
    `
    margin-top: 24px;
    background: ${theme.color.background.secondary};
    border: 1px solid ${theme.color.main.primary};
    color: ${theme.color.text.primary.black};
    
    &:hover {
      background: ${theme.color.background.outline};
    }
  `}
`;

const NotAccountBlock = styled.div`
  margin: 24px 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    flex-wrap: wrap;
  }
`;

const LinkForPage = styled(Link)`
  ${textSmall};
  line-height: 17px;
  font-weight: 600;
  text-align: right;
  color: ${({ theme }) => theme.color.background.blue};
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.5;
  }
`;

export {
  Wrapper,
  HeaderBlock,
  HeaderText,
  HeaderTextBold,
  FormBlock,
  FormTitle,
  FormDescription,
  ActionButton,
  NotAccountBlock,
  LinkForPage
};

import { gql } from '@apollo/client';

export const GET_ALL_SITES = gql`
  query GetSites {
    sites {
      id
      name
      tariff {
        pricePerKwH
        pricePerMinute
        freePeriod
        maxBlockPrice
        priceAdhocPerKwH
        priceAdhocPerMinute
        freeAdhocPeriod
        maxAdhocBlockPrice
      }
    }
  }
`;

export const UPDATE_SITE_SETTINGS = gql`
  mutation updateSiteSettings($site: UpdateSiteInput!, $filter: SiteFilter!) {
    updateSites(site: $site, filter: $filter)
  }
`;

export const INSERT_NEW_TARIFF = gql`
    mutation CreateTariff($tariff: CreateTariffInput!) {
        createTariff(tariff: $tariff) {
            beginn_ts
        }
    }
`

export const GET_ALL_SITE_TARIFFS =gql`
query AllSiteTariffs {
  allSiteTariffs {
    groups {
      group_id
      group_name
      tariffs {
        free_period_in_minutes
        beginn_ts
        price_per_minute
        price_per_kwh
        max_block_price
        id
      }
    }
    id
    name
  }
}
`

import styled from 'styled-components';
import { textSmall } from '../../../styles/texts';
import CommonButton from '../button';

const Wrapper = styled.div`
  width: 590px;
  min-height: 540px;
  background: ${({ theme }) => theme.color.background.secondary};
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 8px;

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    width: 350px;
    min-height: auto;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 22px 16px 18px;
  background: ${({ theme }) => theme.color.main.primary};
  border-radius: 8px;

  div {
    cursor: pointer;
  }

  svg > * {
    fill: ${({ theme }) => theme.color.background.secondary};
  }
`;

const HeaderText = styled.p`
  ${textSmall};
  line-height: 166%;
  color: ${({ theme }) => theme.color.background.secondary};
`;

const Content = styled.div`
  padding: 24px 22px;
`;

const ExplainText = styled.p`
  padding: 0 0 14px;
  ${textSmall};
  line-height: 21px;
  font-weight: 500;
`;

const ActionButton = styled(CommonButton)`
  display: flex;
  align-items: center;
  min-width: 176px;

  ${({ assigned }) =>
    assigned === 'false' &&
    `
    min-width: 176px;
    margin: 0 auto;
  `}

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    margin: 20px auto;
    width: 100%;
  }
`;

const ActionButtonImage = styled.img`
  display: block;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

const CancelButton = styled(CommonButton)`
  display: block;
  min-width: 150px;
  background: ${({ theme }) => theme.color.text.primary.darkGray};
  border-color: ${({ theme }) => theme.color.text.primary.darkGray};
  transition: 0.3s all;

  &:hover {
    background: ${({ theme }) => theme.color.text.primary.lightDark};
  }

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    width: 100%;
  }
`;

const ButtonBlock = styled.div`
  margin-top: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    flex-direction: column;
  }
`;

export {
  Wrapper,
  Header,
  HeaderText,
  ExplainText,
  ActionButton,
  Content,
  ButtonBlock,
  CancelButton,
  ActionButtonImage
};

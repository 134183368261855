import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import {
  Chart,
  Legend,
  Series,
  Tooltip,
  Size,
  Title,
  Font,
  ValueAxis,
  ArgumentAxis,
  Label,
  Grid
} from 'devextreme-react/chart';

import * as S from './styled';
import { GET_CHARGING_SESSION_FOR_CHART } from '../../../apollo/chargingHistory';
import { light } from '../../../styles/themes';
import {roundTo1Decimal} from "../../../utils";
import {charge_amount_formating} from "../../../utils/custom_formating";

const RANGE_OF_DAYS = 30;
const endDate = moment().add(1, 'days').format('YYYY-MM-DD');
const startDate = moment(endDate).add(-RANGE_OF_DAYS, 'days').format('YYYY-MM-DD');

const ChartForCard = ({ evseId }) => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState([]);

  const { data, loading } = useQuery(GET_CHARGING_SESSION_FOR_CHART, {
    variables: {
      chargingSessionFilter: {
        evseId,
        from: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        to: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      }
    }
  });

  useEffect(() => {
    if (data) {
      sortByDays();
    }
  }, [evseId, data]);

  const sortByDays = () => {
    const arrayOfDays = [{ arg: moment(startDate).format('DD MMM'), val: 0 }];

    for (let i = 1; i < RANGE_OF_DAYS; i++) {
      const currentDay = {
        arg: moment(startDate).add(i, 'days').format('DD MMM'),
        val: 0
      };
      arrayOfDays.push(currentDay);
    }

    data?.charging_sessions?.forEach((item) => {
      if (item?.startTime) {
        arrayOfDays?.map((v) => {
          if (v?.arg === moment(item?.startTime)?.format('DD MMM')) {
            v.val += charge_amount_formating(item?.amount);
            return v;
          }

          return v;
        });
      }
    });

    const isHasCharges = arrayOfDays?.find((item) => item.val > 0);

    setChartData(isHasCharges ? [...arrayOfDays] : []);
  };

  const customizePoint = () => {
    return {
      color: light.main.primary
    };
  };

  const customizeTooltip = (e) => {
    return { text: `Amount: ${Math.abs(e.valueText)} kWh` };
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <S.Wrapper>
      <Chart dataSource={chartData} id="chart">
        <Title text={t('chargingHistory.chart_last_days')}>
          <Font size={22} />
        </Title>
        <Size height={200} />
        <Series type="bar" color={light.main.primary} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
        <ValueAxis>
          <Grid visible={false} />
        </ValueAxis>
        <ArgumentAxis>
          <Label visible={false} />
        </ArgumentAxis>
        <Legend visible={false} />
      </Chart>
    </S.Wrapper>
  );
};

export default ChartForCard;

export const validateTariffValues = (e) => {
  return (e.target.value = e.target.value.replace(/[^ 0-9+().]/g, ''));
};

export const validateVerificationID = (value) => {
  const letters = /^[0-9a-fA-F]+$/;
  const splitBySeparator = value?.split(':');
  const valueWithoutSeparator = value?.split(':')?.join('');

  if (!letters.test(valueWithoutSeparator)) {
    return false;
  }

  if (splitBySeparator?.length > 1) {
    if (valueWithoutSeparator?.length !== 8 && valueWithoutSeparator?.length !== 14) {
      return false;
    }

    const arrOfValue = value?.split('');

    for (let i = 0; i < arrOfValue?.length - 2; i++) {
      if (arrOfValue[2] !== ':') return false;

      if (arrOfValue[i] === ':' && i < 2) return false;

      if (arrOfValue[i] === ':' && arrOfValue[i + 2] !== ':') return false;

      return true;
    }
  } else if (
    splitBySeparator?.length === 1 &&
    (valueWithoutSeparator?.length === 8 || valueWithoutSeparator?.length === 14)
  ) {
    return true;
  }
  return false;
};

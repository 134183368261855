import styled from 'styled-components';
import { textMedium } from '../../../../styles/texts';

const Wrapper = styled.div`
  min-height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 14px 16px 14px;
  width: 49%;
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 0 4.5px 10.5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: 0.3s all;

  ${({ animated }) =>
    animated &&
    `
      transform: scale(1.2);
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  min-height: 138px;
  object-fit: cover;
`;

const ListTitle = styled.p`
  ${textMedium};
  line-height: 17px;
  font-weight: 500;
  text-align: center;
`;

const StatusBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 8px 0;
  width: 100%;
  background: ${({ theme, orange, red, blue }) => {
    if (orange) {
      return `${theme.color.background.orange}`;
    } else if (red) {
      return `${theme.color.background.red}`;
    } else if (blue) {
      return `${theme.color.background.blue}`;
    } else {
      return `${theme.color.background.green}`;
    }
  }};
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: ${({ theme }) => theme.color.background.secondary};

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: ${({ theme }) => theme.color.background.secondary};

    * {
      fill: ${({ theme }) => theme.color.background.secondary};
    }
  }
`;

const StatusBlockOccupied = styled.div`
  filter: grayscale(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 8px 0;
  width: 100%;
  background: ${({ theme, orange, red, blue }) => {
    if (orange) {
        return `${theme.color.background.orange}`;
    } else if (red) {
        return `${theme.color.background.red}`;
    } else if (blue) {
        return `${theme.color.background.blue}`;
    } else {
        return `${theme.color.background.green}`;
    }
}};
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: ${({ theme }) => theme.color.background.secondary};

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    fill: ${({ theme }) => theme.color.background.secondary};

    * {
      fill: ${({ theme }) => theme.color.background.secondary};
    }
  }
`;

const StatusBlockText = styled.p`
  ${textMedium};
  line-height: 17px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.background.secondary};
`;

const OfflineBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    * {
      fill: ${({ theme, error }) => (error ? 'red' : theme.color.text.primary.dark)};
    }
  }
`;

const OfflineBlockText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.text.primary.dark};
`;

export { Wrapper, Image, ListTitle, StatusBlock, StatusBlockText, OfflineBlock, OfflineBlockText, StatusBlockOccupied };

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as S from '../../styled';
import Input from '../../../../components/UI/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CommonButton from '../../../../components/UI/button';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../services';
import { ALERT_TYPES } from '../../../../enums';

const PasswordSettings = ({ handlerActions }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(8, t('password_minimum_character'))
      .required(t('auth.password_required'))
      .default(''),
    password: Yup.string()
      .min(8, t('password_minimum_character'))
      .required(t('auth.password_required'))
      .default(''),
    password_repeat: Yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
    })
  });

  const submitForm = async (values, { resetForm }) => {
    try {
      await api.user.updateUserInfo({
        oldPassword: values?.old_password,
        newPassword: values?.password
      });

      handlerActions(t('settings.user_password_update'));
      formik.values = {
        old_password: '',
        password: '',
        password_repeat: ''
      };

      resetForm();
    } catch (e) {
      handlerActions(e?.response?.data?.message, ALERT_TYPES.ERROR);
    }
  };

  const formik = useFormik({
    initialValues: validationSchema.cast(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <S.FormBlock>
      <Input
        label={t('auth.old_password')}
        id="old_password"
        name="old_password"
        type={showPassword ? 'text' : 'password'}
        onChange={formik.handleChange}
        value={formik.values.old_password || ''}
        onBlur={formik.handleBlur}
        error={formik.touched.old_password && Boolean(formik.errors.old_password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Input
        label={t('auth.password')}
        id="password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        onChange={formik.handleChange}
        value={formik.values.password || ''}
        onBlur={formik.handleBlur}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Input
        label={t('auth.password_repeat')}
        id="password_repeat"
        name="password_repeat"
        type={showPassword ? 'type' : 'password'}
        onChange={formik.handleChange}
        value={formik.values.password_repeat || ''}
        onBlur={formik.handleBlur}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.password_repeat && formik.errors.password_repeat}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <CommonButton text={'Submit'} onClick={formik.handleSubmit} />
    </S.FormBlock>
  );
};

export default PasswordSettings;

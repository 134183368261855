import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import * as S from './styled';
import { api } from '../../services';
import { ALERT_TYPES } from '../../enums';

const AutoVerificationUser = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (id) {
      setShowLoader(true);
      api.authenticate
        .verifyAccount(id)
        .then(() => {
          setStatus(ALERT_TYPES.SUCCESS);
        })
        .catch(() => navigate('/login'))
        .finally(() => setShowLoader(false));
    }
  }, []);

  if (showLoader) {
    return <CircularProgress />;
  }

  return (
    <>
      {status === ALERT_TYPES.SUCCESS && (
        <S.Wrapper>
          <S.Title>{t('auth.success_verify')}</S.Title>
          <S.Button text={t('auth.go_to_login')} onClick={() => navigate('/login')} />
        </S.Wrapper>
      )}
    </>
  );
};

export default AutoVerificationUser;

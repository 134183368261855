import styled from 'styled-components';
import { textLarge, textSmall } from '../../../styles/texts';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
  max-width: 1340px;
  display: flex;
  flex-direction: column;
  margin: 14px 0 25px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 15px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    background: transparent;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    flex-direction: column;
    align-items: flex-start;

    button {
      margin-top: 10px;
    }
  }
`;

const HeaderTitle = styled.p`
  display: flex;
  align-items: center;
  ${textLarge};
  font-weight: 500;
  margin-bottom: 5px;

  svg {
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const HeaderSubtitle = styled.p`
  display: flex;
  align-items: center;
  ${textSmall};
  font-weight: 500;
  color: ${({ theme }) => theme.color.text.primary.darkGray};

  span:first-child {
    display: block;
    margin-right: 15px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px 0;
  padding: 15px 20px;

  ${({ activeTab, theme }) =>
    activeTab &&
    `
    justify-content: normal;
    gap: 20px;

    @media(${theme.base.breakpoint.LARGE}) {
      width: 100%;
      justify-content: center;
      gap: 20px;
    }
  `}

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 10px;
  }
`;

const WaitingBlock = styled.div`
  padding: 20px;
`;

// const CardContent = styled.div`
//   width: 80%;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   gap: 20px;
// `;

export { Container, Header, HeaderTitle, HeaderSubtitle, Content, WaitingBlock };

import React, {useEffect} from 'react';
import {useUserInfo} from "../../../../context";
import * as S from "../../styled";
import {createStripeCustomer} from "../../../../services/api/payment";

const PaymentSettings = ({handlerActions}) => {

    const {info: userInfo} = useUserInfo();

    useEffect(() => {
        if(userInfo){
            console.log(userInfo)
            console.log("stripeCustomerId == "+userInfo?.stripeCustomerId)
            if(userInfo?.stripeCustomerId == null){
                console.log("no stripe account -> create one now ")
                const data = {email: userInfo.email, name: userInfo.fullName}
                createStripeCustomer(data).then(
                    res => console.log(res)
                )
            }
        }
    },[])

    const handleSubmit = async () => {
        {/* eslint-disable-next-line no-undef */}
        window.location = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL
    }

    return (<div>
            <S.Title>Payment configuration</S.Title>
            <S.PaymentInfo>
                <S.PaymentInfo>
                    <p>You must configure valid payment methods to be able to use chargebase.</p>
                    <p>The payment configuration is handled with our partners at Stripe.</p>
                    <S.SubTitle> Please follow the following steps: </S.SubTitle>
                    <S.stepByStepList>
                        <S.stepElement>Click on the button below, you will be forwarded to an external customer portal</S.stepElement>
                        <S.stepElement>Enter your current Email-Address ‘{userInfo?.email}’ </S.stepElement>
                        <S.stepElement>Open your Email-Client, you should have received an Email “your link to the customer portal of
                            lade-plus GmbH” – Click on the “Login” - Button in that Email </S.stepElement>
                        <S.stepElement>The customer portal opens in your browser. Please add or modify your payment settings and billing address . </S.stepElement>
                    </S.stepByStepList>
                </S.PaymentInfo>
                <S.LinkButton text={"Open external customer portal"} onClick={handleSubmit}/>
            </S.PaymentInfo>
        </div>
    );

};

export default PaymentSettings;

import React, { useMemo, useState } from 'react';
import {AssignedUsers, EditGroup, EditUser} from './modals';
import UserToken from './modals/UserToken';
import AddUsers from './modals/AddUsers';
import AlertMessage from '../AlertMessage';
import { ALERT_TYPES, USER_ACTION_TYPES } from '../../../enums';
import CreateGroup from "./modals/CreateGroup";

const ActionsWithUser = ({
  handlerCloseModal,
  activeUsers, activeGroups,
  siteValue,
  connectorValue,
  handlerRefetchContract,
  cardPage = false
}) => {
  const [modalStatus, setModalStatus] = useState(USER_ACTION_TYPES.ASSIGNED);
  const [editUserData, setEditUserData] = useState(null);
  const [editTokenData, setEditTokenData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertType, setAlertType] = useState(ALERT_TYPES.SUCCESS);
  const [initGroup, setInitGroup] = useState('');

  const showEditGroup = (value, editData) => {
    setModalStatus(value);
    if (editData) {
      setGroupData(editData);
    }
  };

  const showEditUser = (value, editData, availableGroupData, initGroupId) => {
    setModalStatus(value);
    //console.log("init Group Id "+initGroupId)
    if (editData) {
      setEditUserData(editData);
    }
    if(availableGroupData){
      setGroupData(availableGroupData)
    }
    if(initGroupId){
      setInitGroup(initGroup)
    }
  };

  const showEditToken = (value, editData, userData) => {
    setModalStatus(value);
    if (editData) {
      setEditTokenData(editData);
    }

    if (userData) {
      setUserData(userData);
    }
  };

  const showCreateToken = (value, editData) => {
    setModalStatus(value);
    if (editData) {
      setUserData(editData);
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    setAlertText('');
  };

  const handlerActions = (text, type = ALERT_TYPES.SUCCESS) => {
    setOpenSnackbar(true);
    setAlertText(text);
    setAlertType(type);
  };

  const setStatus = useMemo(() => {
    switch (modalStatus) {
      case USER_ACTION_TYPES.ASSIGNED: {
        return (
          <AssignedUsers
            connectorValue={connectorValue}
            siteValue={siteValue}
            handlerChangeModal={showEditUser}
            handlerActions={handlerActions}
            closeModal={handlerCloseModal}
            activeUsers={activeUsers}
            activeGroups={activeGroups}
            cardPage={cardPage}
            handlerRefetchContract={handlerRefetchContract}
          />
        );
      }
      case USER_ACTION_TYPES.ADD_USER: {
        return (
          <AddUsers
            handlerChangeModal={showEditUser}
            handlerActions={handlerActions}
            siteValue={siteValue}
            availableGroups={groupData}
            connectorValue={connectorValue}
          />
        );
      }
      case USER_ACTION_TYPES.EDIT: {
        return (
          <EditUser
            user={editUserData}
            availableGroups={groupData}
            handlerChangeModal={showEditToken}
            siteValue={siteValue}
            connectorValue={connectorValue}
            handlerEditToken={showEditToken}
            handlerCreateToken={showCreateToken}
            handlerActions={handlerActions}
          />
        );
      }
      case USER_ACTION_TYPES.EDIT_GROUP: {
        return (
            <EditGroup
                group={editUserData}
                handlerChangeModal={showEditGroup}
                handlerActions={handlerActions}
                siteValue={siteValue}
                connectorValue={connectorValue}
            />
        );
      }
      case USER_ACTION_TYPES.CREATE_GROUP: {
        return (
            <CreateGroup
              handlerChangeModal={showEditGroup}
              handlerActions={handlerActions}
              siteValue={siteValue}
              connectorValue={connectorValue}
            />
        );
      }
      case USER_ACTION_TYPES.EDIT_TOKEN: {
        return (
          <UserToken
            edit={true}
            editData={editTokenData}
            userData={userData}
            handlerChangeModal={showCreateToken}
            handlerEditToken={showEditToken}
            handlerCreateToken={showCreateToken}
            handlerActions={handlerActions}
          />
        );
      }
      case USER_ACTION_TYPES.ADD_TOKEN: {
        return (
          <UserToken
            handlerChangeModal={showCreateToken}
            userData={userData}
            handlerEditToken={showEditToken}
            handlerCreateToken={showCreateToken}
            handlerActions={handlerActions}
          />
        );
      }
    }
  }, [modalStatus]);

  return (
    <>
      <AlertMessage
        open={openSnackbar}
        onClose={closeSnackbar}
        errorText={alertText}
        severity={alertType}
      />
      {setStatus}
    </>
  );
};

export default ActionsWithUser;

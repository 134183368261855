import { css } from "styled-components";

export const titleText = css`
  font-size: ${({ theme }) => theme.base.fontSize.XXL};
  line-height: ${({ theme }) => theme.base.lineHeight.XL};
  font-family: ${({ theme }) => theme.base.fontFamily};
  color: ${({ theme }) => theme.color.text.primary.dark};
  font-weight: ${({ theme }) => theme.base.fontWeight.XL};
  @media (${({ theme }) => theme.base.breakpoint.L}) {
    font-size: ${({ theme }) => theme.base.fontSize.XL};
    line-height: ${({ theme }) => theme.base.lineHeight.XL};
  }
`;

export const subTitleText = css`
  font-size: ${({ theme }) => theme.base.fontSize.XL};
  line-height: ${({ theme }) => theme.base.lineHeight.L};
  font-family: ${({ theme }) => theme.base.fontFamily};
  color: ${({ theme }) => theme.color.text.primary.dark};
  font-weight: ${({ theme }) => theme.base.fontWeight.L};
  @media (${({ theme }) => theme.base.breakpoint.L}) {
    font-size: ${({ theme }) => theme.base.fontSize.L};
    line-height: ${({ theme }) => theme.base.lineHeight.L};
  }
`;

export const textLarge = css`
  font-size: ${({ theme }) => theme.base.fontSize.L};
  line-height: ${({ theme }) => theme.base.lineHeight.L};
  font-family: ${({ theme }) => theme.base.fontFamily};
  font-weight: ${({ theme }) => theme.base.fontWeight.XL};
  color: ${({ theme }) => theme.color.text.primary.dark};
  @media (${({ theme }) => theme.base.breakpoint.L}) {
    font-size: ${({ theme }) => theme.base.fontSize.S};
    line-height: ${({ theme }) => theme.base.lineHeight.S};
  }
`;

export const textMedium = css`
  font-size: ${({ theme }) => theme.base.fontSize.M};
  line-height: ${({ theme }) => theme.base.lineHeight.M};
  font-family: ${({ theme }) => theme.base.fontFamily};
  font-weight: ${({ theme }) => theme.base.fontWeight.M};
  color: ${({ theme }) => theme.color.text.primary.dark};
  @media (${({ theme }) => theme.base.breakpoint.L}) {
    font-size: ${({ theme }) => theme.base.fontSize.S};
    line-height: ${({ theme }) => theme.base.lineHeight.S};
  }
`;

export const textSmall = css`
  font-size: ${({ theme }) => theme.base.fontSize.S};
  line-height: ${({ theme }) => theme.base.lineHeight.XS};
  font-family: ${({ theme }) => theme.base.fontFamily};
  font-weight: ${({ theme }) => theme.base.fontWeight.M};
  color: ${({ theme }) => theme.color.text.primary.dark};
  @media (${({ theme }) => theme.base.breakpoint.L}) {
    font-size: ${({ theme }) => theme.base.fontSize.XS};
    line-height: ${({ theme }) => theme.base.lineHeight.XS};
  }
`;

export const textXSmall = css`
  font-size: ${({ theme }) => theme.base.fontSize.XS};
  line-height: ${({ theme }) => theme.base.lineHeight.XS};
  font-family: ${({ theme }) => theme.base.fontFamily};
  font-weight: ${({ theme }) => theme.base.fontWeight.M};
  color: ${({ theme }) => theme.color.text.primary.dark};
`;

export const textXXSmall = css`
  font-size: ${({ theme }) => theme.base.fontSize.XXS};
  line-height: ${({ theme }) => theme.base.lineHeight.XXS};
  font-family: ${({ theme }) => theme.base.fontFamily};
  font-weight: ${({ theme }) => theme.base.fontWeight.M};
  color: ${({ theme }) => theme.color.text.primary.dark};
`;

export const textXSmallPrimaryMain = css`
  ${textXSmall};
  color: ${({ theme }) => theme.color.text.primary.main};
`;

export const textXXSmallPrimaryLight = css`
  ${textXXSmall};
  color: ${({ theme }) => theme.color.text.primary.light};
`;

export const textXSmallPrimaryLightDark = css`
  ${textXSmall};
  color: ${({ theme }) => theme.color.text.primary.lightDark};
`;

export const textSmallPrimaryMain = css`
  ${textSmall};
  color: ${({ theme }) => theme.color.text.primary.main};
`;

export const textSmallSecondaryMainBold = css`
  ${textSmall};
  color: ${({ theme }) => theme.color.text.secondary.main};
  font-weight: ${({ theme }) => theme.base.fontWeight.XL};
`;

export const textSmallSecondaryNormal = css`
  ${textSmall};
  color: ${({ theme }) => theme.color.text.secondary.main};
  font-weight: ${({ theme }) => theme.base.fontWeight.M};
`;

export const textSmallSemiBold = css`
  ${textSmall};
  font-weight: ${({ theme }) => theme.base.fontWeight.L};
`;

export const sidebarText = css`
  ${textMedium};
  color: ${({ theme, active }) =>
    active ? theme.color.main.primary : theme.color.text.primary.main};
`;

export const textMediumSemiBoldSecondaryLight = css`
  ${textMedium};
  color: ${({ theme }) => theme.color.text.secondary.light};
  font-weight: ${({ theme }) => theme.base.fontWeight.L};
`;

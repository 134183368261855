import styled from 'styled-components';
import CommonButton from '../../../button';
import { textSmall, textXXSmall } from '../../../../../styles/texts';
import Select from "../../../select";

const Form = styled.form``;

const TokenBlock = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.gray};
`;

const TokenAddButton = styled(CommonButton)`
  padding: 6px 20px;
  min-width: 86px;
`;

const TokenList = styled.div`
  max-height: 200px;
  overflow-y: scroll;

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    max-height: 150px;
  }
`;

const TokenItemBlock = styled.div`
  margin-bottom: 8px;
  padding: 6px 22px 6px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    padding: 16px 0;
  }
`;

const TokenItemTitle = styled.p`
  ${textSmall};
  line-height: 18px;
  font-weight: 500;
`;
const TokenItemSubTitle = styled.div`
  display: flex;
  align-items: center;

  p {
    ${textXXSmall};
    line-height: 24px;

    &:first-child {
      margin-right: 10px;
    }
  }
`;

const TokenItemIcons = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    cursor: pointer;
    transition: 0.3s all;

    &:first-child {
      margin-right: 18px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const SelectStyle = styled(Select)`
  //max-width: 338px;
`;

export {
  Form,
  TokenBlock,
  TokenAddButton,
  TokenItemBlock,
  TokenList,
  TokenItemTitle,
  TokenItemSubTitle,
  TokenItemIcons,
  SelectStyle
};

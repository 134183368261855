import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    justify-content: space-between;
    padding-top: 16px;
  }
`;

const HeaderImg = styled.img`
  margin-right: 34px;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: block;
    margin-right: 0;
  }
`;

const BurgerMenu = styled.div`
  flex-shrink: 0;
  margin-left: 12px;
  cursor: pointer;
  width: 16px;
  height: 12px;
  display: none;
  flex-direction: column;
  justify-content: space-between;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: flex;
  }
`;

const BurgerMenuItem = styled.span`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.color.background.black};
`;

const MenuContent = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: 0.3s all;
  z-index: 40;
  transform: translateX(-200%);

  ${({ active }) =>
    active &&
    `
    transform: translateY(0);
  `}
`;

const MenuBlur = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20;
`;

export { Wrapper, HeaderImg, BurgerMenu, BurgerMenuItem, MenuContent, MenuBlur };

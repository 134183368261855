import React from 'react';

export const HomeIcon = ({ active }) => {
  if (active) {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.66675 13.3334L14.3342 3.99939C15.3081 3.22026 16.6921 3.22026 17.6659 3.99939L29.3334 13.3334"
          stroke="#F209A1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6368 5.39923L7.30343 9.73257C6.33509 10.5193 5.85092 10.9127 5.59208 11.4565C5.33325 12.0003 5.33325 12.6242 5.33325 13.8719V18.6667C5.33325 23.6949 5.33325 26.2092 6.89535 27.7712C8.45745 29.3333 10.9716 29.3333 15.9999 29.3333C21.0282 29.3333 23.5425 29.3333 25.1045 27.7712C26.6666 26.2092 26.6666 23.6949 26.6666 18.6667V13.8719C26.6666 12.6242 26.6666 12.0003 26.4078 11.4565C26.1489 10.9127 25.6647 10.5193 24.6965 9.73257L19.3631 5.39923C17.7522 4.09035 16.9467 3.43591 15.9999 3.43591C15.0531 3.43591 14.2477 4.09035 12.6368 5.39923Z"
          stroke="#F209A1"
          strokeWidth="2"
        />
        <path d="M13 21.6666H19" stroke="#F209A1" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  }
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66675 13.3334L14.3342 3.99939C15.3081 3.22026 16.6921 3.22026 17.6659 3.99939L29.3334 13.3334"
        stroke="#00051D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6368 5.39935L7.30343 9.73269C6.33509 10.5195 5.85092 10.9128 5.59208 11.4566C5.33325 12.0004 5.33325 12.6243 5.33325 13.872V18.6668C5.33325 23.695 5.33325 26.2093 6.89535 27.7713C8.45745 29.3334 10.9716 29.3334 15.9999 29.3334C21.0282 29.3334 23.5425 29.3334 25.1045 27.7713C26.6666 26.2093 26.6666 23.695 26.6666 18.6668V13.872C26.6666 12.6243 26.6666 12.0004 26.4078 11.4566C26.1489 10.9128 25.6647 10.5195 24.6965 9.73269L19.3631 5.39935C17.7522 4.09047 16.9467 3.43604 15.9999 3.43604C15.0531 3.43604 14.2477 4.09047 12.6368 5.39935Z"
        stroke="#00051D"
        strokeWidth="2"
      />
      <path d="M13 21.6667H19" stroke="#00051D" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

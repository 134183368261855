import React from 'react';

import * as S from './styled';
import Navigation from './Navigation';
import { LogoIcon } from '../../Icons';

const Sidebar = () => {
  return (
    <>
      <S.SidebarContainer>
        <S.SidebarLogo>
          <LogoIcon />
        </S.SidebarLogo>
        <Navigation />
      </S.SidebarContainer>
    </>
  );
};

export default Sidebar;

import styled from 'styled-components';
import { subTitleText, titleText } from '../../styles/texts';
import Select from '../../components/UI/select';
import { motion } from 'framer-motion';
import CommonButton from "../../components/UI/button";

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0 16px;
  }
`;

const Container = styled.div`
  max-width: 1340px;
  width: 100%;
`;

const PopupContainer = styled.div`
  max-width: 1340px;
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 15px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 344px;
  }
`;

const Info = styled.div`
  margin-top: 24px;
  margin-bottom: 14px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    margin-top: 46px;
  }
`;

const SelectStyle = styled(Select)`
  max-width: 338px;
`;

const PaymentInfo = styled.div`
  margin: 24px 24px 18px 14px;

  @media (${({theme}) => theme.base.breakpoint.XL}) {
    margin-top: 46px;
  }
`;

const InfoTitle = styled.p`
  ${titleText};
  margin-bottom: 14px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
  }
`;

const InfoSubtitle = styled.p`
  ${subTitleText};
  color: ${({ theme }) => theme.color.text.primary.darkGray};
`;

const PaymentWarningTitle = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin: 15px 10px 2px 10px;
`;

const LinkButton = styled(CommonButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const stepByStepList = styled.ol`
  margin-left: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  content: counter(item) ". ";
  counter-increment: item;
  display: inline-block;
  list-style-position: inside;
`
const stepElement = styled.li`
    text-align: justify-all;
    margin-bottom: 10px;
`

const ActionsButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PaymentSubTitle = styled.p`
  margin-top: 24px;
  margin-bottom: 10px;
  ${subTitleText};
  font-size: 18px;
`;

export { Wrapper,
  Container,
  Info,
  InfoTitle,
  InfoSubtitle,
  SelectStyle,
  PopupContainer,
  PaymentWarningTitle,
  PaymentInfo,
  LinkButton,
  ActionsButtons,
  stepByStepList,
  stepElement,
  PaymentSubTitle};

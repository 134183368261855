import qs from 'qs';
import lapdev from '../../lapdev';

export function login(data) {
  return lapdev.post('auth/token', qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    auth: {
      username: 'test',
      password: 'test'
    }
  });
}

import styled from 'styled-components';
import { textSmall } from '../../../styles/texts';

const Wrapper = styled.div`
  width: 390px;
  background: ${({ theme }) => theme.color.background.secondary};
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 8px;

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    width: 350px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 22px 16px 18px;
  background: ${({ theme }) => theme.color.main.primary};
  border-radius: 8px;

  div {
    cursor: pointer;
  }

  svg > * {
    fill: ${({ theme }) => theme.color.background.secondary};
  }
`;

const HeaderText = styled.p`
  ${textSmall};
  line-height: 166%;
  color: ${({ theme }) => theme.color.background.secondary};
`;

const ExplainText = styled.p`
  padding: 24px 22px 14px;
  ${textSmall};
`;

const Content = styled.div`
  padding: 14px 12px;
  margin-bottom: 20px;
  max-height: 200px;
  overflow-y: scroll;
`;

const UserItem = styled.div`
  padding: 16px 18px;
  cursor: pointer;
  ${({ theme, active }) =>
    active &&
    `
    background: ${theme.color.background.primary};
  `}
  transition: .3s all;

  &:hover {
    background: ${({ theme }) => theme.color.background.primary};
  }
`;

export { Wrapper, Header, HeaderText, ExplainText, Content, UserItem };

import styled from 'styled-components';
import { textMedium } from '../../../styles/texts';

const TabWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 22px 28px;
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 19px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: none;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  border-radius: 19px;
  background: ${({ theme }) => theme.color.background.gray};
`;

const TabItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;
  ${textMedium};
  font-weight: bold;
  border-radius: 19px;
  color: ${({ theme, active }) =>
    active ? theme.color.background.secondary : theme.color.text.primary.dark};
  cursor: pointer;
  background: ${({ theme, active }) => (active ? theme.color.main.primary : 'transparent')};
  transition: 0.3s all;

  svg > * {
    fill: ${({ theme, active }) =>
      active ? theme.color.background.secondary : theme.color.text.primary.dark};
  }

  p {
    margin-right: 16px;
  }

  &:first-child {
    margin-right: 28px;
  }

  ${({ active }) =>
    active &&
    `
    padding-right: 50px;
    
    &:first-child {
      margin-right: 0;
    }
  `};

  &:hover {
    background: ${({ theme }) => theme.color.main.primary};
    color: ${({ theme }) => theme.color.background.secondary};
    svg > * {
      fill: ${({ theme }) => theme.color.background.secondary};
    }
  }
`;

export { TabWrapper, Tabs, TabItem };

import styled from 'styled-components';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0 16px;
  }
`;

const Content = styled.div`
  margin-top: 24px;
`;

const ContentBlock = styled.div`
  min-height: 70vh;
  margin-top: 20px;
  padding: 20px;
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 0 2px 10px rgba(51, 51, 51, 0.1);
  border-radius: 15px;
  overflow-y: scroll;
`;

export { Wrapper, Content, ContentBlock };

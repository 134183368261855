import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import Input from '../Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { api } from '../../../services';
import AlertMessage from '../AlertMessage';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../../router/pages/routes';
import { ALERT_TYPES } from '../../../enums';

const ResetPasswordBlock = ({ firstStep = true }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [text, setText] = useState('');
  const [alertStatus, setAlertStatus] = useState(ALERT_TYPES.ERROR);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required(t('auth.email_required'))
  });

  const validationSchemaPassword = Yup.object({
    password: Yup.string()
      .min(8, t('password_minimum_character'))
      .required(t('auth.password_required'))
      .default(''),
    password_repeat: Yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
    })
  });

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlerCloseAlert = () => {
    setIsShowAlert(false);
  };

  const submitForm = async (values) => {
    if (firstStep) {
      try {
        const response = await api.authenticate.resetPassword(values?.email);
        setIsShowAlert(true);
        setText(`${response?.data?.message}. Check your email please!`);
        setAlertStatus(ALERT_TYPES.SUCCESS);
        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 1500);
      } catch (e) {
        setIsShowAlert(true);
        setText(e?.response?.data?.message);
        setAlertStatus(ALERT_TYPES.ERROR);
      }
    } else {
      try {
        const data = {
          code: id,
          password: values?.password
        };
        const response = await api.authenticate.resetPasswordConfirm(data);
        setIsShowAlert(true);
        setText(response?.data?.message);
        setAlertStatus(ALERT_TYPES.SUCCESS);
        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 1500);
      } catch (e) {
        setIsShowAlert(true);
        setText(e?.response?.data?.message);
        setAlertStatus(ALERT_TYPES.ERROR);
      }
    }
  };

  const formik = useFormik({
    initialValues: firstStep ? validationSchema.cast() : validationSchemaPassword.cast(),
    validationSchema: firstStep ? validationSchema : validationSchemaPassword,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  return (
    <S.Wrapper>
      <AlertMessage
        open={isShowAlert}
        onClose={handlerCloseAlert}
        errorText={text}
        severity={alertStatus}
      />
      <S.HeaderBlock>
        <S.HeaderText>{t('auth.dont_have_account')}</S.HeaderText>

        <S.HeaderTextBold to={'/sign-in'}>&nbsp;{t('auth.get_started')}</S.HeaderTextBold>
      </S.HeaderBlock>

      <S.FormBlock>
        <S.FormTitle>{firstStep ? t('auth.reset_title') : t('auth.reset_password')}</S.FormTitle>
        <S.FormDescription>
          {firstStep ? t('auth.reset_description') : t('auth.reset_description_password')}
        </S.FormDescription>
        {firstStep ? (
          <Input
            label={t('auth.email_address')}
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email || ''}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        ) : (
          <>
            <Input
              label={t('auth.password')}
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={formik.handleChange}
              value={formik.values.password || ''}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Input
              label={t('auth.password_repeat')}
              id="password_repeat"
              name="password_repeat"
              type={showPassword ? 'type' : 'password'}
              onChange={formik.handleChange}
              value={formik.values.password_repeat || ''}
              onBlur={formik.handleBlur}
              error={formik.touched.password_repeat && Boolean(formik.errors.password_repeat)}
              helperText={formik.touched.password && formik.errors.password_repeat}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </>
        )}

        <S.ActionButton
          text={firstStep ? t('auth.send_link') : t('auth.set_new_password')}
          onClick={formik.handleSubmit}
        />

        <S.NotAccountBlock>
          <S.LinkForPage to={'/login'}>{t('auth.back_to_login')}</S.LinkForPage>
        </S.NotAccountBlock>
      </S.FormBlock>
    </S.Wrapper>
  );
};

export default ResetPasswordBlock;

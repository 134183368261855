import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { CloseIcon } from '../../Icons';

const WarningModal = ({
  onClose,
  handlerProcessedClick,
  title = null,
  description = null,
  showButton = true
}) => {
  const { t } = useTranslation();

  return (
    <S.WarningWrapper>
      <S.Header>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </S.Header>

      <S.Content>
        <S.ContentTitle>{title || t('modal.warning_title')}</S.ContentTitle>
        <S.ContentDescription>
          {description === '' || description ? description : t('modal.warning_description')}
        </S.ContentDescription>
        {showButton ? (
          <S.ContentActionsBlock>
            <S.Button text={t('modal.warning_agree')} onClick={handlerProcessedClick} />
            <S.Button text={t('modal.warning_cancel')} cancel="true" onClick={onClose} />
          </S.ContentActionsBlock>
        ) : null}
      </S.Content>
    </S.WarningWrapper>
  );
};

export default WarningModal;

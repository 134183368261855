import React, { useState } from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';

import { CloseIcon } from '../../Icons';
import * as S from './styled';
import { api } from '../../../services';
import CommonModal from '../CommonModal';
import WarningModal from '../WarningModal';
import { GET_STATUS_FOR_CONNECTOR } from '../../../apollo/chargingHistory';
import {RESET_CHARGEPOINT} from "../../../apollo/connector";

const ActionsBlock = ({
  title,
  onClose,
  chargepointID,
  siteId,
  showErrorWithReset,
  handlerActionWithChargepoint
}) => {
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [resetType, setResetType] = useState('');

  const [updateConnectorStatus] = useLazyQuery(GET_STATUS_FOR_CONNECTOR, {
    skip: siteId === '',
    variables: {
      chargepointFilter: {
        siteId
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [resetChargepoint, { data: reset_response, loading: reset_loading, error: reset_error }] = useMutation(RESET_CHARGEPOINT);

  const handlerOpenWarningModal = (type) => {
    setResetType(type);
    setOpenWarningModal(true);
  };

  const handleReset = async () => {
    const params = {
      chargepoint_id: chargepointID,
      type: resetType
    };

    const variables = {
        chargepointResetFilter: {
          id: chargepointID,
          type: resetType
        }
    }


    handlerActionWithChargepoint(true);

    try {
      //await api.charging.resetChargepoint(params);
      await resetChargepoint({variables})
      if (reset_error != null){
        const errorMessage = reset_error?.message;
        showErrorWithReset(errorMessage);
      } else {
        setTimeout(() => {
          updateConnectorStatus();
          handlerActionWithChargepoint(false);
        }, 5000);
      }
    } catch (e) {
      showErrorWithReset(e?.response?.data?.detail?.[0]);
      setTimeout(() => {
        handlerActionWithChargepoint(false);
      }, 5000);
    } finally {
      onClose();
    }
  };

  const handlerCloseWarningModal = () => {
    setOpenWarningModal(false);
  };

  return (
    <S.ActionsWrapper>
      <CommonModal open={openWarningModal} onClose={handlerCloseWarningModal}>
        <WarningModal
          onClose={handlerCloseWarningModal}
          handlerProcessedClick={handleReset}
          description={''}
        />
      </CommonModal>
      <S.ActionsHeader>
        <p>{title}</p>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </S.ActionsHeader>

      <S.ActionsButtons>
        <S.ButtonReset text={'Reset (Soft)'} onClick={() => handlerOpenWarningModal('Soft')} />
        <S.ButtonReset
          orange="true"
          text={'Reset (Hard)'}
          onClick={() => handlerOpenWarningModal('Hard')}
        />
        {/*<S.ButtonReset red="true" text={'Firmware-Update'} onClick={() => handleReset('Update')} />*/}
      </S.ActionsButtons>
    </S.ActionsWrapper>
  );
};

export default ActionsBlock;

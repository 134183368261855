import React from 'react';
import { Alert } from '@mui/material';

import * as S from './styled';
import { ALERT_TYPES } from '../../../enums';

const AlertMessage = ({
  open,
  severity = ALERT_TYPES.ERROR,
  onClose,
  errorText,
  duration = 4000,
  ...props
}) => {
  return (
    <S.SnackbarWrapper
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={onClose}
      autoHideDuration={duration}
      {...props}>
      <Alert severity={severity}>{errorText}</Alert>
    </S.SnackbarWrapper>
  );
};

export default AlertMessage;

import React from 'react';

import * as S from './styled';

const Checkbox = ({ value, onChecked, label, checked, disabled=false }) => {
  const handlerChecked = (e) => {
    onChecked(e.target.value);
  };

  return (
    <S.FormControlWrapper
      label={label}
      control={disabled? <S.CustomCheckboxDisabled value={value} checked={checked} onChange={handlerChecked} disabled={disabled}/> :
          <S.CustomCheckbox value={value} checked={checked} onChange={handlerChecked} disabled={disabled}/>
      }
    />
  );
};

export default Checkbox;

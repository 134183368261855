import lapdev from '../../lapdev';
import qs from 'qs';
import {
  getRefreshToken,
  removeRefreshToken,
  removeToken,
  setRefreshToken,
  setToken
} from '../../../utils/localStorage';

export function refreshToken() {
  const data = {
    grant_type: 'refresh_token',
    refresh_token: getRefreshToken()
  };

  return lapdev
    .post('auth/token', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: 'test',
        password: 'test'
      }
    })
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        setToken(data?.access_token);
        setRefreshToken(data?.refresh_token);
      }

      return data?.access_token;
    })
    .catch(() => {
      removeRefreshToken();
      removeToken();
      window.location.href = '/login';
    });
}

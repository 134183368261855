import React from 'react';

export const SecondCalendar = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 6.83333H7.99996C5.60673 6.83333 3.66663 8.77343 3.66663 11.1667V24.5C3.66663 26.8932 5.60673 28.8333 7.99996 28.8333H24C26.3932 28.8333 28.3333 26.8932 28.3333 24.5V11.1667C28.3333 8.77343 26.3932 6.83333 24 6.83333Z" stroke="#00051D" strokeWidth="1.5"/>
    <path d="M9 4.16667V8.83333" stroke="#00051D" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M5 12.8333H27" stroke="#00051D" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M23.6666 4.16667V8.83333" stroke="#00051D" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M11 19.1667C11.5523 19.1667 12 18.7189 12 18.1667C12 17.6144 11.5523 17.1667 11 17.1667C10.4477 17.1667 10 17.6144 10 18.1667C10 18.7189 10.4477 19.1667 11 19.1667Z" fill="#00051D"/>
    <path d="M16.3334 19.1667C16.8857 19.1667 17.3334 18.7189 17.3334 18.1667C17.3334 17.6144 16.8857 17.1667 16.3334 17.1667C15.7811 17.1667 15.3334 17.6144 15.3334 18.1667C15.3334 18.7189 15.7811 19.1667 16.3334 19.1667Z" fill="#00051D"/>
    <path d="M21.6666 19.1667C22.2189 19.1667 22.6666 18.7189 22.6666 18.1667C22.6666 17.6144 22.2189 17.1667 21.6666 17.1667C21.1143 17.1667 20.6666 17.6144 20.6666 18.1667C20.6666 18.7189 21.1143 19.1667 21.6666 19.1667Z" fill="#00051D"/>
    <path d="M11 24.5C11.5523 24.5 12 24.0523 12 23.5C12 22.9477 11.5523 22.5 11 22.5C10.4477 22.5 10 22.9477 10 23.5C10 24.0523 10.4477 24.5 11 24.5Z" fill="#00051D"/>
    <path d="M16.3334 24.5C16.8857 24.5 17.3334 24.0523 17.3334 23.5C17.3334 22.9477 16.8857 22.5 16.3334 22.5C15.7811 22.5 15.3334 22.9477 15.3334 23.5C15.3334 24.0523 15.7811 24.5 16.3334 24.5Z" fill="#00051D"/>
    <path d="M21.6666 24.5C22.2189 24.5 22.6666 24.0523 22.6666 23.5C22.6666 22.9477 22.2189 22.5 21.6666 22.5C21.1143 22.5 20.6666 22.9477 20.6666 23.5C20.6666 24.0523 21.1143 24.5 21.6666 24.5Z" fill="#00051D"/>
  </svg>

)
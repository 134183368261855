import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 16px;

  svg {
    &:hover {
      opacity: 1;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 6px;
`;

const NoDataText = styled.p`
  margin-bottom: 10px;
`;

export { Wrapper, Title, NoDataText };

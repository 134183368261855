import React from "react";

export const FirstCalendar = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 6.83333H7.99996C5.60673 6.83333 3.66663 8.77343 3.66663 11.1667V24.5C3.66663 26.8932 5.60673 28.8333 7.99996 28.8333H24C26.3932 28.8333 28.3333 26.8932 28.3333 24.5V11.1667C28.3333 8.77343 26.3932 6.83333 24 6.83333Z" stroke="#00051D" strokeWidth="1.5"/>
    <path d="M9 4.16667V8.83333" stroke="#00051D" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M2.66663 22.1667H29.3333" stroke="#00051D" strokeWidth="1.5"/>
    <path d="M23.6666 4.16667V8.83333" stroke="#00051D" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M13.333 18.5C12.9684 18.5 12.6661 18.1691 12.6661 17.77V14.4701L12.4971 14.6745C12.2481 14.9763 11.8301 14.9957 11.5544 14.7329C11.2787 14.4604 11.2609 14.0028 11.501 13.7011L12.835 12.0755C13.0218 11.8516 13.3153 11.7737 13.5731 11.8808C13.8311 11.9879 14 12.2605 14 12.5622V17.77C14 18.1788 13.7066 18.5 13.333 18.5Z" fill="#00051D"/>
    <path d="M18 18.5C16.526 18.5 15.3334 17.3029 15.3334 15.8236V14.5097C15.3334 13.0304 16.526 11.8333 18 11.8333C19.474 11.8333 20.6667 13.0304 20.6667 14.5097V15.8236C20.6667 17.3029 19.474 18.5 18 18.5ZM18 13.3029C17.331 13.3029 16.7879 13.848 16.7879 14.5195V15.8333C16.7879 16.5049 17.331 17.0499 18 17.0499C18.6691 17.0499 19.2122 16.5049 19.2122 15.8333V14.5195C19.2122 13.848 18.6691 13.3029 18 13.3029Z" fill="#00051D"/>
  </svg>

)
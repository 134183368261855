import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { ListTab } from '../../Icons';
import { CardTab } from '../../Icons/CardTab';
import { TAB_TYPES } from '../../../enums';

const TabsBlock = ({ handlerChangeTab }) => {
  const [activeTab, setActiveTab] = useState(TAB_TYPES.LIST);
  const { t } = useTranslation();

  const handlerCheckTab = (tabName) => {
    if (tabName !== activeTab) {
      setActiveTab(tabName);
      handlerChangeTab(tabName);
    }
  };

  return (
    <S.TabWrapper>
      <S.Tabs>
        <S.TabItem
          active={activeTab === TAB_TYPES.LIST}
          onClick={() => handlerCheckTab(TAB_TYPES.LIST)}>
          <p>{t('tabs.list_tab')}</p>
          <ListTab />
        </S.TabItem>
        <S.TabItem
          active={activeTab === TAB_TYPES.CARD}
          onClick={() => handlerCheckTab(TAB_TYPES.CARD)}>
          <p>{t('tabs.card_tab')}</p>
          <CardTab />
        </S.TabItem>
      </S.Tabs>
    </S.TabWrapper>
  );
};

export default TabsBlock;

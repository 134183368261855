import api from '../../api';

export default (instance) => {
  return instance.interceptors.response.use(null, async (err) => {
    const originalConfig = err.config;
    if (err && err.response && err.response.status === 401) {
      await api.authenticate.refreshToken();
      return instance(originalConfig);
    }
    if (err && err.response && err.response.status === 403) {
      console.log('403 ', err.response);
    }
    return Promise.reject(err);
  });
};

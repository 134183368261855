import React, { useState } from 'react';

import * as S from './styled';
import SmallProfileBar from '../SmallProfileBar';
import TabsBlock from '../TabsBlock';
import smallImg from '../../../assets/icons/smallLogoIcon.svg';
import BurgerMenuContent from './BurgerMenuContent';

const ContentHeader = ({ handlerCheckTab, showTab = true }) => {
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);

  const handlerChangeTab = (value) => handlerCheckTab(value);

  const handlerOpenMenu = () => {
    setIsOpenBurgerMenu(true);
    document.body.style.overflow = 'hidden';
  };

  const handlerCloseMenu = () => {
    setIsOpenBurgerMenu(false);
    document.body.style.overflow = 'visible';
  };

  return (
    <S.Wrapper>
      <S.BurgerMenu onClick={handlerOpenMenu}>
        <S.BurgerMenuItem />
        <S.BurgerMenuItem />
        <S.BurgerMenuItem />
      </S.BurgerMenu>
      <S.MenuContent active={isOpenBurgerMenu}>
        <S.MenuBlur onClick={handlerCloseMenu} />
        <BurgerMenuContent onClose={handlerCloseMenu} />
      </S.MenuContent>
      <S.HeaderImg src={smallImg} />
      {showTab && <TabsBlock handlerChangeTab={handlerChangeTab} />}
      <SmallProfileBar showTab={showTab} />
    </S.Wrapper>
  );
};

export default ContentHeader;

import styled from 'styled-components';
import { TreeList } from 'devextreme-react/tree-list';

const TreeListBlock = styled(TreeList)`
  margin-top: 22px;
`;

const ConnectorBlock = styled.div`
  display: inline-block;
  color: white;
  padding: 8px 20px;
  border-radius: 25px;
  background: ${({ theme }) => theme.color.background.primary};
  margin-top: 8px;
`;

const ConnectorItem = styled.div`
  display: inline-block;
  padding: 4px 10px;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.background.primary};
  margin-top: 4px;
`;

const DeleteButton = styled.div`
  color: deeppink;
`

const Wrapper = styled.div``;

export { TreeListBlock, Wrapper, ConnectorItem, DeleteButton, ConnectorBlock };

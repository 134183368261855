import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonModal from '../CommonModal';
import WarningModal from '../WarningModal';

const ErrorModal = ({ title, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <CommonModal open={open} onClose={onClose}>
      <WarningModal
        onClose={onClose}
        title={t('modal.error')}
        showButton={false}
        description={title || ''}
      />
    </CommonModal>
  );
};

export default ErrorModal;

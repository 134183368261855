import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Input from '../../../Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { api } from '../../../../../services';
import { ALERT_TYPES, ROLES_TYPES, USER_ACTION_TYPES } from '../../../../../enums';
import {useMutation} from "@apollo/client";
import {GET_GROUP_BY_ID, INVITE_USER} from "../../../../../apollo/group";
import {GET_CONTRACT_BY_ID} from "../../../../../apollo/contracts";
import {GET_CONNECTOR_BY_ID} from "../../../../../apollo/connector";

const AddUsers = ({ handlerChangeModal, handlerActions, siteValue, connectorValue, availableGroups }) => {
  const { t } = useTranslation();

  const [groupOptions, setGroupOptions] = useState(null);
  const [selectedGroup, setSelectGroup] = useState('');

  const [inviteUser] = useMutation(INVITE_USER,{
    refetchQueries: [
      {
        query: GET_GROUP_BY_ID,
        variables: {
          groupFilter: {
            site_id: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONTRACT_BY_ID,
        variables: {
          contractFilter: {
            siteId: +siteValue?.id
          }
        }
      },
      {
        query: GET_CONNECTOR_BY_ID,
        variables: {
          filter: {
            evseId: connectorValue?.evseId
          }
        }
      }
    ]
  })

  useEffect(() => {

    // ADHOC group is not allowed
    const groups_available = availableGroups?.filter((x) => x.group_name !== "ADHOC" ).map(
        (group) => {
          //console.log(group)
          return {
            id: group.group_id,
            value: group.group_id,
            name: group.group_name
          }
        }
    )
    setGroupOptions(groups_available)
    const groupDefault = groups_available.find((item)=> item.name==="REGULAR")
    setSelectGroup(groupDefault.value || groups_available?.[0]?.value)
  },[availableGroups])

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required(t('auth.email_required')),
    fullName: Yup.string().required(t('auth.name_required'))
  });

  const submitForm = async (value) => {

    const data = {
      fullName: value?.fullName,
      email: value?.email,
      siteId: siteValue?.id,
      contractName: value?.fullName,
      role: ROLES_TYPES.USER,
      groupId: selectedGroup
    };

    const contract = {
      name: value?.fullName,
      email: value?.email
    };

    try {
      const response = await api.invitation.inviteUser(data);
      contract.evcoId = response?.data?.evcoId;
      contract.groupId = selectedGroup;
      handlerActions(response?.data?.message);
      await inviteUser(
          {
            variables:{
              inviteUser: {
                name: "createTest1",
                siteId: 3,
                group_id: 5
              }
            }
          }
      )
      handlerChangeModal(USER_ACTION_TYPES.EDIT,contract);
    } catch (e) {
      handlerActions(e?.response?.data?.message, ALERT_TYPES.ERROR);
    }
  };

  const formik = useFormik({
    initialValues: validationSchema.cast(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  const handlerSelectGroup = (e) => {
    const currentValue = groupOptions?.find((item) => item.value === e.target.value);
    setSelectGroup(currentValue?.value);
  }


  return (
    <CommonModalWithHeader
      title={t('modal.add_new_user')}
      subtitle={t('modal.added_explain_text')}
      buttonText={t('modal.submit')}
      onClose={() => handlerChangeModal(USER_ACTION_TYPES.ASSIGNED)}
      buttonFunc={formik.handleSubmit}>
      <S.Form>
        <Input
          label={t('auth.email_address')}
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Input
          label={t('auth.full_name')}
          id="fullName"
          name="fullName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.fullName || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
        />

        <Input
          label={t('auth.site')}
          id="site"
          name="site"
          type="text"
          onChange={formik.handleChange}
          disabled={true}
          value={siteValue?.name}
          onBlur={formik.handleBlur}
        />

        <Input
          label={t('auth.role')}
          id="role"
          name="role"
          type="text"
          onChange={formik.handleChange}
          disabled={true}
          value={ROLES_TYPES.USER}
          onBlur={formik.handleBlur}
        />
        {/*<Input label={t('modal.email')} />*/}
      </S.Form>
      {groupOptions && (
          <S.SelectStyle options={groupOptions} onChange={handlerSelectGroup} value={selectedGroup} label={t('auth.group')}/>
      )}
    </CommonModalWithHeader>
  );
};

export default AddUsers;

import styled from "styled-components";
import Select from "../../../select";

const Form = styled.form``;

const SelectStyle = styled(Select)`
  //max-width: 338px;
`;

export {
    Form,
    SelectStyle
}
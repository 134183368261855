import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import { useQuery, NetworkStatus } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import * as S from './styled';
import HistoryCard from '../../components/UI/HistoryCard';
import {
  GET_CHARGING_SESSIONS_BY_SITE,
  GET_STATUS_FOR_CONNECTOR
} from '../../apollo/chargingHistory';
import ContentHeader from '../../components/UI/ContentHeader';
import { GET_ALL_SITES } from '../../apollo/settings';
import { TAB_TYPES } from '../../enums';
import { useUserInfo } from '../../context';
import CommonModal from "../../components/UI/CommonModal";
import routes from "../../router/pages/routes";
import {createStripeCustomer} from "../../services/api/payment";

const OverviewWallboxes = () => {
  const {info: userInfo} = useUserInfo();
  const location = useLocation();
  const { t } = useTranslation();
  const { isSiteAdmin } = useUserInfo();

  const [activeTab, setActiveTab] = useState(TAB_TYPES.LIST);
  const [selectOptions, setSelectOptions] = useState(null);
  const [selectValue, setSelectValue] = useState('');
  const [isOpenPaymentInfoWarning, setPaymentInfoWarning] = useState(false);

  const { data: allSites, loading: siteLoading } = useQuery(GET_ALL_SITES);
  const { loading, data } = useQuery(GET_CHARGING_SESSIONS_BY_SITE, {
    skip: selectValue === '',
    variables: {
      siteFilter: {
        id: selectValue
      }
    }
  });
  const { data: statusData, networkStatus } = useQuery(GET_STATUS_FOR_CONNECTOR, {
    skip: !data && selectValue === '',
    variables: {
      chargepointFilter: {
        siteId: selectValue
      }
    },
    pollInterval: 15000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (userInfo?.email){
      if(userInfo?.stripeCustomerId == null){
        console.log("no stripe account -> create one now ")
        const data = {email: userInfo.email, name: userInfo.fullName}
        createStripeCustomer(data).then(
            res => console.log(res)
        )
      }
      if(userInfo.suspended){
        setPaymentInfoWarning(true)
      }
    }
  }, [userInfo?.email]);

  useEffect(() => {
    if (allSites?.sites?.length) {
      const options = allSites?.sites?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name
        };
      });

      setSelectOptions(options);
      setSelectValue(options[0]?.value);
    }
  }, [allSites]);

  useEffect(() => {
    if (location.state) {
      setSelectValue(+location.state);
    }
  }, [location.state]);

  const handlerCheckTab = (value) => {
    setActiveTab(value);
  };

  const handlerSelect = (e) => {
    const currentValue = selectOptions?.find((item) => item.id === e.target.value);
    setSelectValue(currentValue?.value);
  };

  const handlerClosePaymentInfoWarning = () => {
    setPaymentInfoWarning(false);
  };

  const handleSubmit = async () => {
    {/* eslint-disable-next-line no-undef */}
    window.location = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL
  }

  return (
    <S.Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}>
      <ContentHeader handlerCheckTab={handlerCheckTab} />
      <S.Container>
        <S.Info>
          <S.InfoTitle>{t('chargingHistory.header_title')}</S.InfoTitle>
          {isSiteAdmin && <S.InfoSubtitle>{t('chargingHistory.header_subtitle')}</S.InfoSubtitle>}
        </S.Info>

        {selectOptions && (
          <S.SelectStyle options={selectOptions} onChange={handlerSelect} value={selectValue} />
        )}
        <CommonModal open={isOpenPaymentInfoWarning} onClose={handlerClosePaymentInfoWarning} title={"Payment Info Warning"} >
            <S.PopupContainer>
                <S.PaymentWarningTitle>Payment method required</S.PaymentWarningTitle>
                <S.PaymentInfo>
                    <p>You must configure valid payment methods to be able to use chargebase.</p>
                    <p>The payment configuration is handled with our partners at Stripe.</p>
                    <S.PaymentSubTitle> Please follow the following steps: </S.PaymentSubTitle>
                    <S.stepByStepList>
                      <S.stepElement>Click on the button below, you will be forwarded to an external customer portal</S.stepElement>
                      <S.stepElement>Enter your current Email-Address ‘{userInfo?.email}’ </S.stepElement>
                      <S.stepElement>Open your Email-Client, you should have received an Email “your link to the customer portal of
                        lade-plus GmbH” – Click on the “Login” - Button in that Email </S.stepElement>
                      <S.stepElement>The customer portal opens in your browser. Please add or modify your payment settings and billing address. </S.stepElement>
                    </S.stepByStepList>
                  <S.ActionsButtons>
                    <S.LinkButton text={"Open external customer portal"} onClick={handleSubmit}/>
                  </S.ActionsButtons>
                </S.PaymentInfo>
            </S.PopupContainer>
        </CommonModal>
        {loading ||
        siteLoading ||
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables ? (
          <CircularProgress />
        ) : data?.sites?.[0]?.chargepoints?.length ? (
          data?.sites?.[0]?.chargepoints?.map((item) => (
            <HistoryCard
              activeTab={activeTab}
              value={item}
              key={item.id}
              siteId={selectValue}
              siteValue={data?.sites?.[0]}
              statusData={statusData?.chargepoints?.find((s) => s?.id === item?.id)}
            />
          ))
        ) : (
          <p>No data...</p>
        )}
      </S.Container>
    </S.Wrapper>
  );
};

export default OverviewWallboxes;

import styled from 'styled-components';
import { textSmall, textXXSmall, textXXSmallPrimaryLight } from '../../../styles/texts';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableWrapper = styled.div`
  margin-bottom: 20px;

  .Container-wrapper {
    display: flex;
    align-items: center;
  }

  th {
    ${textXXSmallPrimaryLight};
    font-weight: bold;
    padding: 0;
    border: none;
  }

  td {
    padding: 20px 0;
    ${textSmall};
    font-weight: bold;
  }

  .MuiToolbar-gutters.Toolbar-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }

  @media (${({ theme }) => theme.base.breakpoint.L}) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody td {
      padding-top: 30px;
    }

    td {
      border: none;
      border-bottom: 1px solid #92959d;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-transform: capitalize;
      color: ${({ theme }) => theme.color.text.primary.main};
    }

    td.TableStubCell-cell:before {
      display: none;
    }

    tr.Row-row {
      display: flex;

      & > td {
        padding-left: 0;
        border-bottom: none;
      }

      ${({ isUser }) =>
        isUser &&
        `
        flex-wrap: wrap;
        
        td {
          display:none;
          width: 49%;
        }
        
        td:first-child {
          display: block;
          width: 100%;
        }
        
        td:nth-of-type(2) {
          padding-top: 0;
          padding-left: 50px;
        }
        
        td:nth-of-type(3) {
          padding-top: 0;
          padding-left: 20px;
        }
        
        td:before {
          display: none;        
        }
      `};
    }

    td:nth-of-type(2):before {
      ${({ type }) => {
        return type === 'info' ? `content: 'Date:'` : `content: 'Connector:'`;
      }}
    }

    td:nth-of-type(3):before {
      ${({ type }) => {
        return type === 'user' ? `content: 'Date:'` : `content: 'Duration:'`;
      }}
    }

    td:nth-of-type(4):before {
      ${({ type }) => {
        return type === 'user' ? `content: 'Duration:'` : `content: 'Cost:'`;
      }}
    }

    td:nth-of-type(5):before {
      ${({ type }) => {
        return type === 'user' ? `content: 'Cost:'` : `content: 'Amount:'`;
      }}
    }

    td:nth-of-type(6):before {
      ${({ type }) => {
        return type === 'user' ? `content: 'Amount:'` : `content: 'User:'`;
      }}
    }

    td:nth-of-type(7):before {
      content: 'user:';
    }

    tbody td.TableStubCell-cell {
      display: none;
    }

    .css-1igu60v-MuiTableRow-root {
      margin-bottom: 50px;
    }
  }
`;

const ListImageBlock = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 56px;
  height: 90px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
`;

const ListImage = styled.img`
  max-width: 60px;
  max-height: 60px;
  object-fit: cover;
`;

const GroupTitleBlock = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.text.primary.darkGray};
  }
`;

const TableFirstColumn = styled(Table.Cell)`
  display: flex;
  align-items: center;
  padding: 18px 0;

  p {
    white-space: normal;
    ${textSmall};
    font-weight: bold;

    &:last-child {
      ${textXXSmall};
    }
  }
`;

const TableUserColumn = styled.div`
  padding: 8px 18px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
  white-space: normal;
  text-align: center;
  font-weight: bold;
`;

const CarTitleBlock = styled.div``;

export {
  TableWrapper,
  TableFirstColumn,
  TableUserColumn,
  CarTitleBlock,
  ListImageBlock,
  GroupTitleBlock,
  ListImage
};

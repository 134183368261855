import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import CommonModalWithHeader from '../../../../../components/UI/CommonModalWithHeader';
import Input from '../../../../../components/UI/Input';
import { api } from '../../../../../services';
import Select from '../../../../../components/UI/select';
import { GET_ALL_SITES } from '../../../../../apollo/settings';
import uuid from 'react-uuid';
import { ALERT_TYPES, ROLES_TYPES } from '../../../../../enums';

const SELECT_ROLE = [
  { id: 1, value: ROLES_TYPES.SITE_ADMIN, name: 'Admin' },
  { id: 2, value: ROLES_TYPES.USER, name: 'User' }
];

const ModalInviteUser = ({ onClose, handlerAction }) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_ALL_SITES);

  const [sitesOptions, setSitesOptions] = useState(null);

  useEffect(() => {
    if (data?.sites?.length) {
      const options = data?.sites?.map((item) => {
        return {
          id: item.id,
          value: item.id,
          name: item.name
        };
      });

      setSitesOptions(options);
    }
  }, [data]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required(t('auth.email_required')),
    fullName: Yup.string().required(t('auth.name_required')),
    role: Yup.string().required(t('auth.name_required')),
    site: Yup.string().required(t('auth.name_required'))
  });

  const submitForm = async (value) => {
    const evcoId = uuid();

    const data = {
      email: value?.email,
      fullName: value?.fullName,
      contractName: value?.fullName,
      siteId: value?.site,
      role: value?.role,
      evcoId
    };

    try {
      const response = await api.invitation.inviteUser(data);
      handlerAction(response?.data?.message);

      onClose();
    } catch (e) {
      handlerAction(e?.response?.data?.message, ALERT_TYPES.ERROR);
    }
  };

  const formik = useFormik({
    initialValues: validationSchema.cast(),
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <CommonModalWithHeader
      title={t('modal.invite_user')}
      buttonText={t('modal.invite')}
      onClose={onClose}
      buttonFunc={formik.handleSubmit}>
      <form>
        <Input
          label={t('auth.email_address')}
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Input
          label={t('auth.full_name')}
          id="fullName"
          name="fullName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.fullName || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          helperText={formik.touched.fullName && formik.errors.fullName}
        />

        <Select
          id="role"
          name="role"
          options={SELECT_ROLE}
          label="Role"
          onChange={formik.handleChange}
          value={formik.values.role || ''}
          onBlur={formik.handleBlur}
          error={formik.touched.role && Boolean(formik.errors.role)}
        />

        {sitesOptions && (
          <Select
            id="site"
            name="site"
            options={sitesOptions}
            label="Site"
            onChange={formik.handleChange}
            value={formik.values.site || ''}
            onBlur={formik.handleBlur}
            error={formik.touched.site && Boolean(formik.errors.site)}
          />
        )}
      </form>
    </CommonModalWithHeader>
  );
};

export default ModalInviteUser;

import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styled';

const NavigationItem = ({ route, Icon, active, text, ...rest }) => {
  return (
    <Link to={route} {...rest}>
      <S.NavBarItem active={active}>
        {Icon}
        <S.NavigationItemText active={active ? 1 : 0}>{text}</S.NavigationItemText>
        {active && <S.ActiveBorder />}
      </S.NavBarItem>
    </Link>
  );
};

export default NavigationItem;

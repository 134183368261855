import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { textMedium, textSmall, titleText } from '../../../styles/texts';
import CommonButton from '../button';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    min-height: auto;
  }
`;

const HeaderBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 40px 40px 0 0;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: none;
  }
`;

const HeaderText = styled.p`
  ${textSmall};
  line-height: 22px;
  font-weight: 500;
`;

const HeaderTextBold = styled(Link)`
  ${textSmall};
  line-height: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.background.black};
  text-decoration: underline;
`;

const FormBlock = styled.form`
  margin: 0 auto;
  max-width: 480px;
  width: 100%;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    max-width: 344px;
  }
`;

const FormTitle = styled.h1`
  margin-bottom: 8px;
  ${titleText};
  line-height: 36px;
`;

const FormDescription = styled.p`
  margin-bottom: 32px;
  ${textMedium};
  line-height: 24px;
  color: ${({ theme }) => theme.color.background.darkGray};
`;

const ActionButton = styled(CommonButton)`
  width: 100%;
  padding: 11px 0;
  display: block;
  font-weight: 700;
  line-height: 26px;
`;

const DivideBlock = styled.div`
  position: relative;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 16px;
  ${textSmall};
  color: ${({ theme }) => theme.color.background.darkGray};

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 220px;
    height: 1px;
    background: rgba(145, 158, 171, 0.24);
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 220px;
    height: 1px;
    background: rgba(145, 158, 171, 0.24);
    top: 50%;
    transform: translateY(-50%);
  }
`;

const SocialBlock = styled.div`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SocialBlockItem = styled.div`
  padding: 14px 0;
  width: 32%;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    background: ${({ theme }) => theme.color.background.gray};
  }
`;

const CheckboxBlock = styled.div`
  margin-bottom: 36px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > label {
    margin: 0;

    span:first-child {
      padding-right: 11px;
    }
  }
`;

const ForgotPassword = styled.p`
  width: 60%;
  ${textSmall};
  line-height: 22px;
  font-weight: 600;
  text-align: right;
  color: ${({ theme }) => theme.color.background.blue};
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.5;
  }
`;

const NotAccountBlock = styled.div`
  margin: 24px 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    flex-wrap: wrap;
  }
`;

const NotAccountTitle = styled.p`
  ${textSmall};
  line-height: 17px;
  color: rgba(21, 17, 64, 0.6);

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

const LinkForPage = styled(Link)`
  margin-left: 14px;
  ${textSmall};
  line-height: 17px;
  font-weight: 600;
  text-align: right;
  color: ${({ theme }) => theme.color.background.blue};
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.5;
  }
`;

const Links = styled.p`
  margin-left: 14px;
  margin-right: 8px;
  ${textSmall};
  line-height: 17px;
  font-weight: 600;
  text-align: right;
  color: ${({ theme }) => theme.color.background.blue};
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    margin-left: 8px;
    margin-right: 0;
  }
`;

export {
  Wrapper,
  HeaderBlock,
  HeaderText,
  HeaderTextBold,
  FormBlock,
  FormTitle,
  FormDescription,
  ActionButton,
  DivideBlock,
  SocialBlock,
  SocialBlockItem,
  CheckboxBlock,
  ForgotPassword,
  NotAccountBlock,
  NotAccountTitle,
  Links,
  LinkForPage
};

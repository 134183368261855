import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';

import CommonModalWithHeader from '../CommonModalWithHeader';
import Input from '../Input';
import {
  GET_CHARGEPOINT_BY_ID,
  GET_CONTRACT,
  UPDATE_CHARGEPOINT,
  UPDATE_CONNECTOR
} from '../../../apollo/chargingHistory';
import { GET_CONNECTOR_BY_ID } from '../../../apollo/connector';

const EditTitleModal = ({ onClose, value, chargepoint = false, adaptivePage = false }) => {
  const { t } = useTranslation();

  const [updateChargepoints] = useMutation(UPDATE_CHARGEPOINT, {
    refetchQueries: [
      {
        query: GET_CHARGEPOINT_BY_ID,
        variables: {
          filter: {
            id: value?.id
          }
        }
      }
    ]
  });

  const [updateConnector] = useMutation(UPDATE_CONNECTOR, {
    refetchQueries: adaptivePage
      ? [
          {
            query: GET_CONNECTOR_BY_ID,
            variables: {
              filter: {
                evseId: value?.evseId
              }
            }
          },
          {
            query: GET_CONTRACT,
            variables: {
              filter: {
                evseId: value?.evseId
              }
            }
          }
        ]
      : [
          {
            query: GET_CONNECTOR_BY_ID,
            variables: {
              filter: {
                evseId: value?.evseId
              }
            }
          }
        ]
  });

  const validationSchema = Yup.object({
    title: Yup.string().required(t('auth.name_required'))
  });

  const submitForm = async (values) => {
    if (chargepoint) {
      const variables = {
        chargepoint: {
          name: values?.title
        },
        filter: {
          id: value?.id
        }
      };

      await updateChargepoints({
        variables
      });
      onClose();
    } else {
      const variables = {
        connector: {
          name: values?.title
        },
        filter: {
          evseId: value?.evseId
        }
      };

      await updateConnector({
        variables
      });

      onClose();
    }
  };

  const formik = useFormik({
    initialValues: { title: value?.name },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    validateOnSubmit: true,
    onSubmit: submitForm
  });

  return (
    <CommonModalWithHeader
      title={chargepoint ? t('modal.title_chargepoints') : t('modal.update_connector')}
      buttonText={t('modal.update')}
      onClose={onClose}
      buttonFunc={formik.handleSubmit}>
      <Input
        label={t('modal.title')}
        id="title"
        name="title"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.title || ''}
        onBlur={formik.handleBlur}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
      />
    </CommonModalWithHeader>
  );
};

export default EditTitleModal;

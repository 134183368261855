import React, {useEffect, useRef, useState} from 'react';

import {ALL_CONTRACTS, DELETE_CONTRACT, EDIT_CONTRACT} from "../../../apollo/contracts";
import {
    Column, ColumnChooser, ColumnFixing,
    DataGrid,
    FilterRow,
    HeaderFilter, Item,
    Pager,
    Paging, Scrolling,
    SearchPanel,
    Sorting,
    Toolbar,
    Editing, MasterDetail, Popup, Form
} from "devextreme-react/data-grid";
import { Button } from 'devextreme-react/button';
import * as S from "./styled";
import CustomStore from 'devextreme/data/custom_store';
import {clientForUserTable} from "../../../apollo/client";
import Token from "./token";



const TableUserManagement = () => {

    const [subArray, setSubArray] = useState("Token")
    const [last_update, setLast_update] = useState((new Date()).toISOString());
    const [selectedRows, setSelectedRows] = useState([])

    const datastore = new CustomStore({
        key: 'evcoId',
        load: () => getData(),
        update: (key,value) => updateContract(value,key),
        remove: (key) => softDelete(key)
    })

    const [store, setStore] = useState(datastore);

    const ContractDataGrid = useRef(null);
    //const { loading, data } = useQuery(ALL_CONTRACTS);

    function softDelete(evcoId){
        return clientForUserTable.mutate(
            {
                mutation: DELETE_CONTRACT,
                variables: {
                    filter: {
                        evcoId: evcoId
                    }
                }
            }
        ).then((res) =>{
                reloadDataGrid();
                return res
            }
        )
    }


    function updateContract(value, evcoId){
        return clientForUserTable.mutate(
            {
                mutation: EDIT_CONTRACT,
                variables: {
                    filter: {
                        evcoId: evcoId
                    },
                    contract: {
                        name: value.name,
                        billing_method: value.billing_method ,
                        address_name: value.address_name,
                        address_name2: value.address_name2,
                        address_street: value.address_street,
                        address_city: value.address_city,
                        address_country: value.address_country,
                        invoice_address_company: value.invoice_address_company,
                        invoice_address_name: value.invoice_address_name,
                        invoice_address_name2: value.invoice_address_name2,
                        invoice_address_street: value.invoice_address_street,
                        invoice_address_city: value.invoice_address_city,
                        invoice_address_country: value.invoice_address_country,
                        lexoffice_customer_id: value.lexoffice_customer_id,
                    }
                }
            }
        ).then((res) =>{
                reloadDataGrid();
                return res
            }
        )
    }

    function getData() {
        return clientForUserTable.query({
            query: ALL_CONTRACTS,
            variables: {}
        }).then(
            (res) => {
                let result = res.data.contracts;
                let contracts = JSON.parse(JSON.stringify(result));

                for( let i = 0; i < result.length ; i++ ){
                    contracts[i].account_user = result[i].user?.email;
                    contracts[i].site_id = result[i].site?.id;
                    contracts[i].site_name = result[i].site?.name;
                    const connectors = result[i].connectors || [];
                    let connectorString = ""
                    for (let j = 0; j < connectors.length ; j++){
                        connectorString = connectorString + connectors[j].name+ " ; "
                    }
                    contracts[i].connectorString = connectorString;

                }
                return contracts;
            }
        )
    }

    function reloadDataGrid(){
        console.log("reload dataa")
        ContractDataGrid.current.instance.refresh()
        /*const newCustomStore = new CustomStore({
            key: 'evcoId',
            load: () => getData(),
            update: (key,value) => updateContract(value,key),
            remove: (key) => softDelete(key)
        })*/
        //setStore(newCustomStore)
        setLast_update(
            (new Date()).toISOString()
        )
    }

    function ConnectorCell(cellData) {
        //for (let j = 0; j < connectors.length ; j++){
        //    connectorString = connectorString + connectors[j].evseId + "|" +
        //}

        return (
            <div>
                {cellData?.data?.connectors?.length
                    ? cellData?.data?.connectors?.map((item) => (
                        <S.ConnectorItem key={item?.evseId}>{item?.name}</S.ConnectorItem>
                    ))
                    : null}
            </div>);


    }

    const cellPrepared = (e) => {
        const editColumns = [
            "name",
            "billing_method",
            "address_name",
            "address_name2",
            "address_street",
            "address_city",
            "address_country",
            "invoice_address_company",
            "invoice_address_name",
            "invoice_address_name2",
            "invoice_address_street",
            "invoice_address_city",
            "invoice_address_country",
            "lexoffice_customer_id"
        ]
        if ( (e.rowType === "data" ) && e.column.name) {
            if( !editColumns.includes(e.column.name) && (e.column.name !== "") && (e.column.name !== null) && (e.column.name !== "buttons") ){
                //e.cellElement.style.cssText = "background-color:rgba(144,238,144, 0.1);";
                //e.cellElement.style.cssText = "background-color:rgba(255,0,255, 0.05);";
                e.cellElement.style.cssText = "color:rgba(128,128,128, 1);";
                //e.cellElement.style.cssText = "color: black;";
            } else {
                if( (e.column.name === "name") ){
                    //e.cellElement.style.cssText = "background-color:rgba(144,238,144, 0.1);";
                    //e.cellElement.style.cssText = "background-color:rgba(255,0,255, 0.05);";
                    e.cellElement.style.cssText = "font-weight: bold;";
                    //e.cellElement.style.cssText = "color: black;";
                }
            }
        }
    }


  return (
      <S.Wrapper>

              <DataGrid
                  id="grid"
                  ref={ContractDataGrid}
                  showBorders={false}
                  showColumnLines={false}
                  showRowLines={false}
                  dataSource={store}
                  repaintChangesOnly={true}
                  columnAutoWidth={true}
                  allowColumnResizing={true}
                  columnResizingMode={"widget"}
                  onCellPrepared={cellPrepared}
                  //selectedRowKeys={selectedRows}
                  //onSelectionChanged={setSelectedRows}
              >
                  <Toolbar>
                      <Item location="before">
                          <h2 className="SubTitle">Users</h2>
                      </Item>
                      <Item location="after">
                          <Button
                              icon='refresh'
                              onClick={() => reloadDataGrid()} />
                      </Item>
                      <Item name="saveButton" location="after">
                      </Item>
                      <Item name="revertButton" location="after">
                      </Item>
                      <Item name="columnChooserButton" location="after">
                      </Item>
                      <Item name="searchPanel" location="after">
                      </Item>
                  </Toolbar>
                  <Editing
                      refreshMode={'reshape'}
                      mode="popup"
                      allowDeleting={true}
                      allowUpdating={true}
                      useIcons={true}>
                      <Popup title="Edit" showTitle={true} />
                      <Form>
                          <Item itemType="group" colCount={2} colSpan={2}>
                              <Item dataField="name" />
                              <Item dataField="lexoffice_customer_id" />
                              <Item dataField="billing_method" />
                          </Item>
                          <Item itemType="group" caption="Address" colCount={2} colSpan={2}>
                              <Item dataField="address_name" />
                              <Item dataField="address_name2" />
                              <Item dataField="address_street" />
                              <Item dataField="address_city" />
                              <Item dataField="address_country" />
                              <Item dataField="invoice_address_company" />
                              <Item dataField="invoice_address_name" />
                              <Item dataField="invoice_address_name2" />
                              <Item dataField="invoice_address_street" />
                              <Item dataField="invoice_address_city" />
                              <Item dataField="invoice_address_country" />
                          </Item>
                      </Form>
                  </Editing>
                  <Paging enabled={false} pageSize={0} />
                  {/*<Pager
                      visible={true}
                      allowedPageSizes={[10, 20 , "all"]}
                      displayMode={"full"}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true} />*/}
                  {/*<HeaderFilter visible={true} />*/}
                  {/*<FilterRow visible={true}
                             applyFilter={{
                                 key: 'auto',
                                 name: 'Immediately',
                             }} />*/}
                  <Sorting mode="multiple" />
                  <SearchPanel visible={true}
                               width={240}
                               placeholder="Search..." />
                  <Scrolling
                      rowRenderingMode='standard'
                  />
                  <ColumnFixing enabled={false} />
                  {/* or "dragAndDrop" */}
                  <ColumnChooser
                      enabled={true}
                      mode="select"
                  />
                  <MasterDetail
                      enabled={true}
                      component={Token}
                  />

                  <Column dataField="name" caption="name" dataType="string"  visible={true}>
                  </Column>

                  <Column dataField="account_user" caption="account_user" dataType="string" allowEditing={false} visible={true}>
                  </Column>

                  <Column dataField="site_id" caption="site_id" dataType="number" allowEditing={false} visible={false}>
                  </Column>

                  <Column dataField="site_name" caption="site_name" dataType="string" allowEditing={false} visible={true}>
                  </Column>

                  <Column dataField="evcoId" caption="evcoId" dataType="string" allowEditing={false} visible={true} >
                  </Column>

                  <Column dataField="tenant" caption="tenant" dataType="string" allowEditing={false} visible={false}>
                  </Column>

                  <Column dataField="connectors" caption="connectors" cellRender={ConnectorCell} allowEditing={false} visible={false}>
                  </Column>

                  <Column dataField="connectorString" caption="connectors_string" dataType="string" allowEditing={false} visible={false}>
                  </Column>

                  <Column dataField="billing_method" caption="billing_method" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="address_name" caption="address_name" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="address_name2" caption="address_name2" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="address_street" caption="address_street" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="address_city" caption="address_city" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="address_country" caption="address_country" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="invoice_address_company" caption="invoice_address_company" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="invoice_address_name" caption="invoice_address_name" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="invoice_address_name2" caption="invoice_address_name2" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="invoice_address_street" caption="invoice_address_street" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="invoice_address_city" caption="invoice_address_city" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="invoice_address_country" caption="invoice_address_country" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column dataField="lexoffice_customer_id" caption="lexoffice_customer_id" dataType="string" allowEditing={true} visible={false}>
                  </Column>

                  <Column caption="delete/edit" type="buttons" visible={true} >
                  </Column>

              </DataGrid>

      </S.Wrapper>
  );
};

export default TableUserManagement;

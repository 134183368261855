import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import CommonModalWithHeader from "../../../CommonModalWithHeader";
import {ALERT_TYPES, USER_ACTION_TYPES} from "../../../../../enums";
import * as S from "../EditGroup/styled";
import Input from "../../../Input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {GET_GROUP_BY_ID, UPDATE_GROUP} from "../../../../../apollo/group";
import {useMutation} from "@apollo/client";
import {GET_CONTRACT_BY_ID} from "../../../../../apollo/contracts";
import {GET_CONNECTOR_BY_ID} from "../../../../../apollo/connector";
import {billingTypes} from "../../../../BillingTypes/billingTypes";


const EditGroup = ({
                       group,
                       handlerChangeModal,
                       handlerActions,
                       siteValue,
                       connectorValue,
                   }) => {
    const { t } = useTranslation();

    const [editGroup, { error }] = useMutation(UPDATE_GROUP,{
        refetchQueries: [
            {
                query: GET_GROUP_BY_ID,
                variables: {
                    groupFilter: {
                        site_id: +siteValue?.id
                    }
                }
            },
            {
                query: GET_CONTRACT_BY_ID,
                variables: {
                    contractFilter: {
                        siteId: +siteValue?.id
                    }
                }
            },
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            }
        ]
    });

    const [selectedBillingMethod, setSelectedBillingMethod] = useState("")

    useEffect(() => {
        //console.log(BILLING_TYPE_OPTIONS)
        if(selectedBillingMethod===""){
            setSelectedBillingMethod(group.billing_method)
        }
    },[])

    const validationSchema = Yup.object({
        groupName: Yup.string().required(t('auth.name_required'))
    });

    const handlerSelectBillingMethod = (e) => {
        setSelectedBillingMethod(e.target.value)
    }

    const submitForm = async (value) => {
        const variables = {
                filter: {
                    group_id: group.group_id,
                },
                group:{
                    group_name: value.groupName,
                    billing_method: selectedBillingMethod
                }
        }

        await editGroup({
            variables
        });

        handlerActions(t('modal.group_edit_success'));
        handlerChangeModal(USER_ACTION_TYPES.ASSIGNED);
    };

    const formik = useFormik({
        initialValues: { groupName: group?.group_name },
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: true,
        validateOnSubmit: true,
        onSubmit: submitForm
    });

    if (error) {
        handlerActions(error.message, ALERT_TYPES.ERROR);
    }

    return (
        <CommonModalWithHeader
            title={t('modal.edit_group')}
            subtitle={t('modal.edit_group_explain_text')}
            buttonText={t('modal.submit')}
            onClose={() => handlerChangeModal(USER_ACTION_TYPES.ASSIGNED)}
            buttonFunc={formik.handleSubmit}>
            <S.Form>
                <Input
                    label={t('auth.group_name')}
                    id="groupName"
                    name="groupName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.groupName || group?.group_name}
                    onBlur={formik.handleBlur}
                    error={formik.touched.groupName && Boolean(formik.errors.groupName)}
                    helperText={formik.touched.groupName && formik.errors.groupName}
                />

                {/*<Input
                    label={t('auth.billing_method')}
                    id="billingMethod"
                    name="billingMethod"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.billingMethod || group?.billing_method}
                    onBlur={formik.handleBlur}
                    error={formik.touched.billingMethod && Boolean(formik.errors.billingMethod)}
                    helperText={formik.touched.billingMethod && formik.errors.billingMethod}
                />*/}

                <S.SelectStyle options={billingTypes()} onChange={handlerSelectBillingMethod} value={selectedBillingMethod} label={t('auth.billing_method')}/>

            </S.Form>
        </CommonModalWithHeader>
    );
};

export default EditGroup
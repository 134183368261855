import {useTranslation} from "react-i18next";


export const billingTypes = () => {
    const { t } = useTranslation();

    const BILLING_TYPE_OPTIONS = [
        {
            id: "none",
            name:t('settings.none'),
            value:"none"
        },
        {
            id: "invoice",
            name:t('settings.invoice'),
            value:"invoice"
        },
        {
            id: "automatic_payment",
            name:t('settings.automatic_payment'),
            value:"automatic_payment"
        }
    ]

    return BILLING_TYPE_OPTIONS;
}
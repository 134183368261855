export const sortByField = (field) => {
  return (a, b) => (a[field] > b[field] ? 1 : -1);
};

export function sortByKey(array, key) {
  return array.sort(function(a, b) {
    console.log(a)
    console.log(b)
    const x = a[key]; const y = b[key];
    return x.localeCompare(y);
  });
}
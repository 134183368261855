import styled from 'styled-components';
import { textSmall } from '../../../styles/texts';
import CommonButton from '../button/CommonButton';

const ActionsWrapper = styled.div`
  width: 364px;
  padding: 14px 15px 12px;
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 15px;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    width: 344px;
  }
`;

const ActionsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;

  p {
    ${textSmall}
  }

  div {
    cursor: pointer;
  }
`;

const ActionsButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonReset = styled(CommonButton)`
  margin-bottom: 20px;
  max-width: 268px;
  width: 100%;
  background: ${({ theme, orange, red }) => {
    if (orange) {
      return theme.color.background.orange;
    } else if (red) {
      return theme.color.background.red;
    }

    return theme.color.background.green;
  }};
`;

export { ActionsWrapper, ActionsHeader, ActionsButtons, ButtonReset };

import styled from 'styled-components';
import { subTitleText } from '../../styles/texts';
import { motion } from 'framer-motion';
import CommonButton from "../../components/UI/button";

const Wrapper = styled(motion.div)`
  width: 100%;
  min-height: 65vh;

  @media (${({ theme }) => theme.base.breakpoint.XL}) {
    padding: 0 16px;
  }
`;

const Title = styled.p`
  margin-top: 24px;
  margin-bottom: 10px;
  ${subTitleText};
  font-weight: bold;
`;

const SubTitle = styled.p`
  margin-top: 24px;
  margin-bottom: 10px;
  ${subTitleText};
  font-size: 18px;
`;

const Content = styled.div`
  min-height: 70vh;
  margin-top: 20px;
  padding: 20px;
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 0 2px 10px rgba(51, 51, 51, 0.1);
  border-radius: 15px;
  overflow-y: scroll;
`;

const FormBlock = styled.form``;

const PaymentInfo = styled.div`
  font-size: 18px;
  width: 100%;
  padding-bottom: 20px;
`

const PaymentSubmitButtons = styled.div`
  padding-top: 20px;
`
const PaymentSubmitButton = styled(CommonButton)`
  margin-right: 10px;
`
const LinkButton = styled(CommonButton)`
  margin-right: 10px;
`

const stepByStepList = styled.ol`
  margin-left: 30px;
  margin-right: 10px;
  content: counter(item) ". ";
  counter-increment: item;
  display: inline-block;
  list-style-position: inside;
`
const stepElement = styled.li`
    text-align: justify-all;
    margin-bottom: 10px;
`

export { Wrapper, FormBlock, Title, Content, PaymentInfo, PaymentSubmitButtons, PaymentSubmitButton, LinkButton, stepByStepList, stepElement, SubTitle };

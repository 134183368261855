import { createGlobalStyle } from 'styled-components';

import DMSansRegular from '../assets/fonts/DMSans-Regular.ttf';
import DMSansMedium from '../assets/fonts/DMSans-Medium.ttf';
import DMSansBold from '../assets/fonts/DMSans-Bold.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DM sans';
    src: url(${DMSansRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM sans';
    src: url(${DMSansMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM sans';
    src: url(${DMSansBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.base.fontFamily};
  }


  a {
    text-decoration: none;
  }

`;

export default GlobalStyle;
